import React from "react";
import heroImage from "../assets/images/webp/hero-image1.webp";
import CustomButton from "./common/CustomButton";
const Hero = () => {
  return (
    <div
      id="home"
      className="text-[#FEFEFE] relative after:absolute after:top-0 after:start-0 after:h-full after:w-full after:bg-[#023E48] after:rounded-xl after:-z-10 bg-hero bg-cover bg-no-repeat mx-3 sm:mx-4 rounded-xl"
    >
      <div className="max-w-[1164px] px-3 mx-auto container py-12 md:py-16 lg:py-20 md:h-[calc(100vh-90px)] flex">
        <div className="flex justify-center max-lg:flex-col-reverse items-center">
          <div className="w-full sm:w-10/12 md:w-9/12 lg:w-6/12 sm:px-3 text-center lg:text-start mt-8 lg:mt-0">
            <h1 className="text-[26px] md:text-4xl lg:text-[40px] xl:text-[44px] font-semibold !leading-[140%]">
              Simplified Online &nbsp;
              <span className="bg-white text-[#023E48] rounded-lg px-2">
                Assignment
              </span>
              &nbsp;Solutions
            </h1>
            <p className="text-xs sm:text-sm mt-4 mb-6">
              Empowering colleges and universities with customized online
              assignment solutions. Our platform streamlines the assignment
              process with real-time analytics, adaptive learning technologies,
              and secure testing environments, helping educators bridge learning
              gaps and improve student outcomes.
            </p>
            <CustomButton className="!bg-white !text-[#023E48] hover:!bg-[#023E48] hover:border-white hover:!text-white">
              Contact Us
            </CustomButton>
          </div>
          <div className="w-full sm:w-10/12 md:w-9/12 lg:w-6/12 sm:px-3 flex justify-center">
            <img
              src={heroImage}
              alt="hero"
              className="w-full rounded-lg h-full object-cover max-w-[536px]"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
