import React from "react";
import Faqs from "../Faqs";
import ContactUs from "../ContactUs";
import About from "../About";
import CountriesSlider from "../CountriesSlider";
import Hero from "../Hero";

const Home = () => {
  return (
    <>
      <Hero />
      <Faqs />
      <ContactUs />
      <About />
      <CountriesSlider />
    </>
  );
};

export default Home;
