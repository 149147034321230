"use client";
import { Minus, Plus } from "lucide-react";
import { useState } from "react";
import { FaqsList } from "./common/Helper";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@radix-ui/react-accordion";
import CustomHeading from "./common/CustomHeading";

const Faqs = () => {
  const [open, setOpen] = useState(-1);
  const handleOpen = (value) => setOpen(open === value ? -1 : value);
  return (
    <div
      id="faq"
      className="py-12 md:py-16 lg:py-20 px-3 container max-w-[1000px] mx-auto"
    >
      <CustomHeading className="text-center leading-[140%]">Frequently asked <span className="bg-[#023E48] text-white px-2 rounded-lg">Questions</span></CustomHeading>
      <div className="mt-6">
        <Accordion
          type="single"
          collapsible
          className="w-full max-w-[776px] mx-auto"
        >
          {FaqsList.map((obj, index) => {
            return (
              <AccordionItem
                value={`item-${index}`}
                key={index}
                className="border-b-0 bg-white rounded shadow-white-shadow mt-4"
              >
                <AccordionTrigger
                  onClick={() => handleOpen(index)}
                  className={`py-3 px-3 md:px-5 lg:px-[30px] text-base md:text-lg hover:no-underline text-start flex justify-between items-center w-full ${
                    open === index
                      ? "rounded-t text-black"
                      : "text-grey bg-white rounded"
                  }`}
                >
                  {obj.title}
                  {open === index ? (
                    <Minus className="stroke-black size-5 min-w-[20px]" />
                  ) : (
                    <Plus className="stroke-black size-5 min-w-[20px]" />
                  )}
                </AccordionTrigger>
                <AccordionContent className="max-w-[669px] w-full px-3 md:px-5 lg:px-[30px] text-sm md:text-base !leading-157 pb-3 md:pb-4">
                  {obj.description}
                </AccordionContent>
              </AccordionItem>
            );
          })}
        </Accordion>
      </div>
    </div>
  );
};

export default Faqs;
