import React from "react";
import aboutIcon from "../assets/images/png/canada-flag.avif";
import CustomHeading from "./common/CustomHeading";
import { aboutUsList } from "./common/Helper";
const About = () => {
  return (
    <div id="about-us" className="max-w-[1164px] mx-auto px-3 py-20 container">
      <CustomHeading className="text-center mb-10">
        <span className="bg-[#023E48] text-white px-2 rounded-lg">About</span>{" "}
        US
      </CustomHeading>
      <div className="flex flex-wrap justify-center gap-y-6">
        {aboutUsList.map((obj, index) => {
          return (
            <div key={index} className="w-full sm:w-6/12 lg:w-4/12 sm:px-3">
              <div className="rounded-2xl p-4 xl:p-5 border border-black border-opacity-[12%] hover:bg-[#F7F9FA] hover:border-transparent duration-300 ease-linear h-full">
                <div className="flex items-center gap-[18px]">
                  <p className="text-lg md:text-xl !leading-150 font-bold">
                    {obj.title}
                  </p>
                </div>
                <p className="text-sm md:text-base !leading-150 font-normal opacity-70 mt-2 sm:mt-2.5 md:mt-3.5 text-rich-black">
                  {obj.description}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default About;
