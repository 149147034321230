import React from "react";

const CustomButton = ({ children, className }) => {
  return (
    <button
      className={`bg-[#023E48] text-white text-base py-4 px-5 !leading-[100%] border border-transparent hover:text-[#023E48] rounded-full hover:bg-white hover:border-[#023E48] transition-all duration-300 ease-in-out ${className}`}
    >
      {children}
    </button>
  );
};

export default CustomButton;
