import React, { useEffect, useState } from "react";
import { NavLinkList } from "./Helper";
import CustomButton from "./CustomButton";
import { CrossIcon, MenuIcon } from "lucide-react";
import logo from "../../assets/images/svg/ak-logo.svg";

const Header = () => {
  const [navOpen, setNavOpen] = useState(false);
  useEffect(() => {
    if (navOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
    return () => {
      document.body.style.overflow = "";
    };
  }, [navOpen]);
  return (
    <div className="sticky top-0 z-30 w-full bg-white shadow-nav-shadow">
      <div className="max-w-[1164px] px-3 mx-auto container flex justify-between items-center py-3">
        <a
          href="#"
          rel="noopener noreferrer"
          aria-label="links"
          className="text-2xl lg:text-3xl relative z-50"
          onClick={() => setNavOpen(false)}
        >
          <img
            src={logo}
            alt="logo"
            width={70}
            className="w-[60px] md:w-[70px]"
          />
        </a>
        <ul
          className={`flex gap-6 max-sm:flex-col max-sm:fixed max-sm:top-0 max-sm:-start-full max-sm:w-full max-sm:h-screen bg-white z-40 max-sm:justify-center max-sm:items-center transition-all ease-in-out duration-300 ${
            navOpen && "!start-0"
          }`}
        >
          {NavLinkList.map((obj, index) => {
            return (
              <li key={index}>
                <a
                  onClick={() => setNavOpen(false)}
                  href={obj.url}
                  rel="noopener noreferrer"
                  aria-label="links"
                  className="text-xl sm:text-base text-[#343434] font-semibold relative after:absolute after:w-0 after:h-[1.5px] after:bg-[#343434] after:start-0 after:-bottom-[1px] hover:after:w-full after:transition-all after:duration-300 after:ease-in-out"
                >
                  {obj.title}
                </a>
              </li>
            );
          })}
        </ul>
        <div className="flex items-center gap-4">
          <CustomButton className="!py-3">Log In</CustomButton>
          <span
            className="relative z-50 sm:hidden"
            onClick={() => setNavOpen(!navOpen)}
          >
            {navOpen ? <CrossIcon className="rotate-45" /> : <MenuIcon />}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Header;
